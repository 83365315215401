import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { WebsocketContext } from '../App';
import './ChessMatch.css';
import ChessBoard from './ChessBoard';
import MatchStartSound from '../assets/audio/Match-start.mp3';
import MatchEndSound from '../assets/audio/Match-end.mp3';
import useSound from 'use-sound';
import soundOn from '../assets/images/sound-on.svg';
import soundOff from '../assets/images/sound-off.svg';
import resignFlag from '../assets/images/resign.svg';

export const EVENT_TYPE = {
  // player 1 create match → match ready → join match;
  // player 2 join match → match pending → accept - match ready → join match;
  CREATE_MATCH: 'createMatch',
  JOIN_MATCH: 'joinMatch',
  MATCH_PENDING: 'matchPending',
  MATCH_READY: 'matchReady',
  MATCH_INVALID: 'matchInvalid',
  GAME_MOVE: 'gameMove',
  GAME_RESIGN: 'gameResign',
  MATCH_OVER: 'matchOver',
  MATCH_FULL: 'matchFull',
  FORFEIT: 'forfeit',
};

function ChessMatch() {
  const ws = useContext(WebsocketContext);
  let { id } = useParams();
  const [orientation, setOrientation] = useState();
  const [gameFen, setGameFen] = useState();
  const [opponentMove, setOpponentMove] = useState();
  const [matchFull, setMatchFull] = useState();
  const [matchReady, setMatchReady] = useState();
  const [matchInvalid, setMatchInvalid] = useState();
  const [matchResult, setMatchResult] = useState('');
  const [viewOnly, setViewOnly] = useState(false);
  const [enableMoveSound, setEnableMoveSound] = useState(true);
  const [matchStartSound] = useSound(MatchStartSound, { volume: 0.7 });
  const [matchEndSound] = useSound(MatchEndSound, { volume: 0.7 });

  const playMatchEndSound = useCallback(() => {
    matchEndSound();
  }, [matchEndSound]);

  const playMatchStartSound = useCallback(() => {
    matchStartSound();
  }, [matchStartSound]);

  const sendWs = useCallback(
    (data) => {
      ws.send(JSON.stringify(data));
    },
    [ws],
  );

  const resign = useCallback(() => {
    sendWs({ eventType: EVENT_TYPE.GAME_RESIGN, matchId: id });
  }, [id, sendWs]);

  useEffect(() => {
    return () => {
      resign();
    };
  }, [resign]);

  // WebSocket event handlers
  useEffect(() => {
    // First player comes from Create new match screen
    if (ws && ws.readyState === ws.OPEN) {
      sendWs({ eventType: EVENT_TYPE.JOIN_MATCH, matchId: id });
    }

    // Second player tries to join the match
    ws.onopen = () => {
      sendWs({ eventType: EVENT_TYPE.JOIN_MATCH, matchId: id });
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.eventType === EVENT_TYPE.MATCH_INVALID) {
        setMatchInvalid(true);
      }

      if (data.eventType === EVENT_TYPE.MATCH_PENDING) {
        setMatchReady(false);
      }

      if (data.eventType === EVENT_TYPE.MATCH_READY) {
        sendWs({ eventType: EVENT_TYPE.JOIN_MATCH, matchId: id });
      }

      if (data.eventType === EVENT_TYPE.GAME_MOVE) {
        setOpponentMove(data.move);
      }

      if (data.eventType === EVENT_TYPE.JOIN_MATCH) {
        setMatchReady(true);
        setOrientation(data.color);
        playMatchStartSound();
      }

      if (data.eventType === EVENT_TYPE.MATCH_OVER || data.eventType === EVENT_TYPE.GAME_RESIGN) {
        setMatchResult(data.matchResult);
        setGameFen(data.gameFen);
        setViewOnly(true);
        setMatchReady(true);
        playMatchEndSound();
      }

      if (data.eventType === EVENT_TYPE.MATCH_FULL) {
        setMatchFull(true);
      }

      if (data.eventType === EVENT_TYPE.FORFEIT) {
        setMatchResult(data.matchResult);
        setViewOnly(true);
      }
    };
  }, [ws, id, sendWs, playMatchStartSound, playMatchEndSound]);

  const joinMatch = () => {
    sendWs({ eventType: EVENT_TYPE.MATCH_READY, matchId: id });
  };

  const moveHandler = (data) => {
    sendWs({
      eventType: EVENT_TYPE.GAME_MOVE,
      move: data.move,
      gameFen: data.fen,
      matchId: id,
    });
  };

  const matchEndHandler = (matchResult) => {
    sendWs({
      eventType: EVENT_TYPE.MATCH_OVER,
      matchResult,
      matchId: id,
    });
  };

  return (
    <div className="chess-match">
      {matchResult ? <div className="match-state">{matchResult}</div> : null}
      {matchInvalid ? (
        <div className="match-state">Match not found</div>
      ) : matchFull ? (
        <div className="match-state">Match is full and currently in progress</div>
      ) : matchReady ? (
        <>
          <ChessBoard
            myMove={moveHandler}
            opponentMove={opponentMove}
            orientation={orientation}
            gameFen={gameFen}
            onMatchEnded={matchEndHandler}
            viewOnly={viewOnly}
            enableSound={enableMoveSound}
          />
          <div className="match-buttons">
            <div>
              <img src={resignFlag} alt="Resign" title="Resign" className="function-btn" onClick={resign} />
            </div>
            <div onClick={() => setEnableMoveSound(!enableMoveSound)}>
              {enableMoveSound ? (
                <img src={soundOn} alt="Turn off move sound" className="function-btn" title="Turn off move sound" />
              ) : (
                <img src={soundOff} alt="Turn on move sound" className="function-btn" title="Turn on move sound" />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="join-match">
          <p className="join-match__text">Your friend is waiting</p>
          <button onClick={joinMatch} className="join-match__btn">
            Join the match
          </button>
        </div>
      )}
    </div>
  );
}

export default ChessMatch;
