import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import 'react-chessground/dist/styles/chessground.css';
import './ChessBoard.css';
import Chess from 'chess.js';
import Chessground from 'react-chessground/chessground';
import bQueen from '../assets/images/bQ.svg';
import wQueen from '../assets/images/wQ.svg';
import bRook from '../assets/images/bR.svg';
import wRook from '../assets/images/wR.svg';
import bBishop from '../assets/images/bB.svg';
import wBishop from '../assets/images/wB.svg';
import bKnight from '../assets/images/bN.svg';
import wKnight from '../assets/images/wN.svg';
import { useOuterClick } from '../utils';
import MoveSound from '../assets/audio/Move.mp3';
import CaptureSound from '../assets/audio/Capture.mp3';
import useSound from 'use-sound';

const STARTING_POSITION_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

function ChessBoard(props) {
  const [chess] = useState(new Chess());
  // Chess board notation, with starting position as default
  const [fen, setFen] = useState(STARTING_POSITION_FEN);
  const [history, setHistory] = useState([]);
  const [showPromotion, setShowPromotion] = useState(false);
  const [pendingMove, setPendingMove] = useState([]);
  const [lastMove, setLastMove] = useState([]);
  const [sideInCheck, setSideInCheck] = useState();
  const promotionChoiceRef = useOuterClick(() => {
    // Handle clicking outside of promotion choice
    setShowPromotion(false);
    setPendingMove([]);
  });
  const [moveSound] = useSound(MoveSound, { volume: 0.5, soundEnabled: props.enableSound });
  const [captureSound] = useSound(CaptureSound, { volume: 0.5, soundEnabled: props.enableSound });

  const turnColor = useCallback(() => {
    return chess.turn() === 'w' ? 'white' : 'black';
  }, [chess]);

  const setBoard = useCallback(
    (from, to) => {
      setFen(chess.fen());
      setHistory(chess.history({ verbose: true }));
      setLastMove([from, to]);
      // Reset any pending move
      setPendingMove([]);
      setShowPromotion(false);
    },
    [chess],
  );

  const isInCheck = useCallback(() => {
    if (chess.in_check()) {
      setSideInCheck(turnColor());

      return true;
    } else {
      setSideInCheck(null);

      return false;
    }
  }, [chess, turnColor]);

  const checkGameResult = useCallback(() => {
    return chess.game_over();
  }, [chess]);

  const determineGameResult = useCallback(() => {
    let result;
    if (chess.in_checkmate()) {
      result = `${chess.turn() === 'w' ? 'Black' : 'White'} won by checkmate!`;
    }
    if (chess.in_stalemate()) {
      result = "The game's drawn by stalemate!";
    } else if (chess.in_draw()) {
      result = "The game's drawn by insufficient material!";
    }
    if (chess.in_threefold_repetition()) {
      result = "The game's drawn because board position has occurred three or more times!";
    }

    props.onMatchEnded(result);
  }, [chess, props]);

  const onMove = useCallback(
    (from, to) => {
      let isMyTurn = turnColor() === props.orientation;

      // Check for pawn's capture on last row (1 or 8)
      const moves = chess.moves({ verbose: true });
      for (let i = 0; i < moves.length; i++) {
        if (moves[i].promotion && moves[i].from === from) {
          setShowPromotion(true);
          setPendingMove([from, to]);

          return;
        }
      }

      const move = chess.move({ from, to, promotion: '' });
      if (move !== null) {
        isInCheck();
        setBoard(from, to);
        if (isMyTurn) {
          // Emit my move
          props.myMove({ move: [from, to], fen: chess.fen() });
        }
        checkGameResult() && determineGameResult();
        // Play move sound
        if (move.captured) {
          captureSound();
        }
        moveSound();
      }
    },
    [captureSound, checkGameResult, chess, determineGameResult, isInCheck, moveSound, props, setBoard, turnColor],
  );

  const onMovePromotion = useCallback(
    (from, to, piece) => {
      let isMyTurn = turnColor() === props.orientation;

      const move = chess.move({ from, to, promotion: piece });
      if (move !== null) {
        isInCheck();
        setBoard(from, to);
        if (isMyTurn) {
          // Emit my move
          props.myMove({ move: [from, to, piece], fen: chess.fen() });
        }
        checkGameResult() && determineGameResult();
        // Play move sound
        if (move.captured) {
          captureSound();
        }
        moveSound();
      }
    },
    [captureSound, checkGameResult, chess, determineGameResult, isInCheck, moveSound, props, setBoard, turnColor],
  );

  // Make opponent move handler
  useEffect(() => {
    if (props.opponentMove) {
      const [from, to, promotePiece] = props.opponentMove;
      // Proceed opponent move
      if (promotePiece) {
        onMovePromotion(from, to, promotePiece);
      } else {
        onMove(from, to);
      }
    }
  }, [props.opponentMove, chess, onMovePromotion, onMove]);

  // Set game FEN (last position) when someone accesses to a match after the match is over
  useEffect(() => {
    if (props.gameFen) {
      setFen(props.gameFen);
    }
  }, [props.gameFen, chess]);

  const selectPromotionPiece = (piece) => {
    const from = pendingMove[0];
    const to = pendingMove[1];

    onMovePromotion(from, to, piece);

    // Make compiler shut up about this unused variable until we implement the feature that `history` involves
    JSON.stringify(history);
  };

  const calculateMovable = () => {
    // https://github.com/ornicar/chessground/issues/148#issuecomment-674579912
    const validMoves = new Map();
    chess.SQUARES.forEach((square) => {
      const moves = chess.moves({ square, verbose: true });
      if (moves.length)
        validMoves.set(
          square,
          moves.map((move) => move.to),
        );
    });

    return {
      free: false,
      dests: validMoves,
      color: props.orientation,
      events: {
        after: () => {},
      },
    };
  };

  return (
    <div className="chess-board">
      {/*More information at https://github.com/ornicar/chessground/blob/7cd550870f7ad1a73056359f97dceb6e5e611d87/src/ts#L32*/}
      <Chessground
        fen={fen}
        orientation={props.orientation}
        onMove={onMove}
        movable={calculateMovable()}
        lastMove={lastMove}
        turnColor={turnColor()}
        check={sideInCheck}
        viewOnly={props.viewOnly}
      />
      {showPromotion &&
        (turnColor() === 'white' ? (
          <div className="promotion-overlay" ref={promotionChoiceRef}>
            <img src={wQueen} alt="Queen" onClick={() => selectPromotionPiece('q')} />
            <img src={wRook} alt="Rook" onClick={() => selectPromotionPiece('r')} />
            <img src={wBishop} alt="Bishop" onClick={() => selectPromotionPiece('b')} />
            <img src={wKnight} alt="Knight" onClick={() => selectPromotionPiece('n')} />
          </div>
        ) : (
          <div className="promotion-overlay" ref={promotionChoiceRef}>
            <img src={bQueen} alt="Queen" onClick={() => selectPromotionPiece('q')} />
            <img src={bRook} alt="Rook" onClick={() => selectPromotionPiece('r')} />
            <img src={bBishop} alt="Bishop" onClick={() => selectPromotionPiece('b')} />
            <img src={bKnight} alt="Knight" onClick={() => selectPromotionPiece('n')} />
          </div>
        ))}
    </div>
  );
}

ChessBoard.propTypes = {
  orientation: PropTypes.string,
  myMove: PropTypes.func,
  opponentMove: PropTypes.array,
  gameFen: PropTypes.string,
  onMatchEnded: PropTypes.func,
  viewOnly: PropTypes.bool,
  enableSound: PropTypes.bool,
};

ChessBoard.defaultProps = {
  orientation: 'white',
  myMove: null,
  opponentMove: null,
  gameFen: STARTING_POSITION_FEN,
  onMatchEnded: null,
  viewOnly: false,
  enableSound: true,
};

export default ChessBoard;
