import React from 'react';
import './Footer.css';
import { useLocation, useHistory } from 'react-router-dom';
// import githubIcon from '../assets/images/github.svg';
// import gitlabIcon from '../assets/images/gitlab.svg';
// import linkedInIcon from '../assets/images/linkedin.svg';

function Footer() {
  const location = useLocation();
  const history = useHistory();

  return (
    <footer className="footer">
      <div className="about">
        Made with 🖥️ by{' '}
        <a href="https://fpooon.com" target="_blank" rel="noreferrer">
          Fpoon
        </a>
      </div>
      {/*<div className="social">
        <a href="https://github.com/vuongtrunghieu" target="_blank" rel="noreferrer" className="social__link">
          <img src={githubIcon} alt="GitHub" className="social__icon" />
        </a>
        <a href="https://gitlab.com/vuongtrunghieu" target="_blank" rel="noreferrer" className="social__link">
          <img src={gitlabIcon} alt="Gitlab" className="social__icon" />
        </a>
        <a href="https://www.linkedin.com/in/hieuvt" target="_blank" rel="noreferrer" className="social__link">
          <img src={linkedInIcon} alt="Linkedin" className="social__icon" />
        </a>
      </div>*/}
      <nav>
        {location.pathname.includes('/match/') && (
          <div onClick={() => history.push('/')} title="Create new match">
            New Match
          </div>
        )}
      </nav>
    </footer>
  );
}

export default Footer;
