import React, { createContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import ChessMatch from './components/ChessMatch';
import Footer from './components/Footer';

export const WebsocketContext = createContext(new WebSocket(process.env.REACT_APP_WS));

function App() {
  return (
    <div className="root">
      <Router>
        <main>
          <Switch>
            <Route path="/match/:id">
              <ChessMatch />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
