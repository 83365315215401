// https://stackoverflow.com/a/54292872
import { useRef, useEffect } from 'react';

export function useOuterClick(callback) {
  const callbackRef = useRef(); // Initialize mutable callback ref
  const innerRef = useRef(); // Return to client, who sets the "border" element

  // Update callback on each render, so second useEffect has most recent callback
  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    function handleClick(e) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
        callbackRef.current(e);
      }
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []); // No dependencies → stable click listener

  return innerRef; // Convenience for clients (doesn't need to init ref themselves)
}
