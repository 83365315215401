import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { WebsocketContext } from '../App';
import './Home.css';
import copyIcon from '../assets/images/copy.svg';
import checkIcon from '../assets/images/check.svg';
import loadingIcon from '../assets/images/loading.svg';
import { EVENT_TYPE } from './ChessMatch';

const BASE_URL = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

function Home() {
  const ws = useContext(WebsocketContext);
  const history = useHistory();
  const [matchId, setMatchId] = useState();
  const [copied, setCopied] = useState(false);
  const [newMatchText, setNewMatchText] = useState('Connecting to server...');

  const navigateToMatch = useCallback(() => {
    if (matchId) {
      history.push(`/match/${matchId}`);
    }
  }, [matchId, history]);

  // Clean up match id to start fresh
  useEffect(() => {
    return () => {
      setMatchId(null);
    };
  }, []);

  // WebSocket event handlers
  useEffect(() => {
    if (ws && ws.readyState === ws.OPEN) {
      setNewMatchText('New Match');
    }

    ws.onopen = () => {
      setNewMatchText('New Match');
    };

    ws.onerror = () => {
      setNewMatchText('Cannot establish connection to server. Try again later!');
    };

    ws.onclose = () => {
      setNewMatchText('Disconnected from server.');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.eventType === EVENT_TYPE.MATCH_READY) {
        navigateToMatch();
      }
    };
  }, [ws, navigateToMatch]);

  const createMatch = () => {
    if (ws && ws.readyState === ws.OPEN) {
      const randomString = [...Array(4)].map(() => Math.random().toString(36)[5]).join('');
      setMatchId(randomString);

      ws.send(JSON.stringify({ eventType: EVENT_TYPE.CREATE_MATCH, matchId: randomString }));
    }
  };

  const copyMatchUrl = () => {
    const url = BASE_URL + `/match/${matchId}`;

    const input = document.createElement('input');
    input.setAttribute('value', url);
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);

    setCopied(result);
  };

  return (
    <div className="home">
      <p className="create-match-text">Challenge your friend with a chess game</p>
      {!matchId ? (
        <button onClick={createMatch} className="create-match-btn" disabled={matchId}>
          {newMatchText}
        </button>
      ) : null}
      {matchId ? (
        <div className="match">
          <p className="create-match-text">Send this link below to your friend and let them join to start the match!</p>
          <div className="match__url">
            <input readOnly value={`${BASE_URL}/match/${matchId}`} />
            <button onClick={copyMatchUrl}>
              {!copied ? <img src={copyIcon} alt="Copy" /> : <img src={checkIcon} alt="Copied" />}
            </button>
          </div>
          {matchId ? (
            <div className="match__waiting">
              <p>Waiting for your friend</p>
              <img src={loadingIcon} alt="..." />
            </div>
          ) : null}
          {/*<button onClick={handleMatchNavigation} className="match-navigate-btn">
            Go to your match
          </button>*/}
        </div>
      ) : null}
    </div>
  );
}

export default Home;
